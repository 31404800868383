import { useCallback, useRef, useEffect } from 'react'
import { jWebReady } from '@jarvis/jweb-core'
import { Logger } from '../utils/helpers'
import {
  CACHE_STRATEGY_CACHEFIRST,
  DHP_ERROR_CODE,
  DHP_REQUEST_REJECTED_ERROR,
  MAX_AGE_IN_SECONDS
} from '../config/constants'

const useDeviceHttpProxy = () => {
  const deviceHttpProxy = useRef(null)

  const sendDHPRequest = useCallback(
    async (options, useCache = false) => {
      try {
        if (useCache && deviceHttpProxy.current.sendRequestWithCacheStrategy) {
          Logger.log('Calling DHP plugin sendRequestWithCacheStrategy')
          return await deviceHttpProxy.current.sendRequestWithCacheStrategy({
            request: options,
            cacheStrategy: CACHE_STRATEGY_CACHEFIRST,
            maxAgeInSeconds: MAX_AGE_IN_SECONDS
          })
        }
        Logger.log('Calling DHP plugin sendRequest')
        return await deviceHttpProxy.current.sendRequest(options)
      } catch (e) {
        const errorType = DHP_ERROR_CODE[e] ? e : DHP_REQUEST_REJECTED_ERROR
        const reason = `Call to DHP plugin has failed - plugin has not been initialized and/or is not available - ${e}`
        Logger.warn(reason)
        return { errorType, reason }
      }
    },
    [deviceHttpProxy]
  )

  useEffect(() => {
    jWebReady
      .then((jweb) => {
        if (jweb && jweb.Plugins && jweb.Plugins.DeviceHttpProxy) {
          deviceHttpProxy.current = jweb.Plugins.DeviceHttpProxy
        } else {
          Logger.error('JWeb plugin: DeviceHttpProxy plugin is not available')
        }
      })
      .catch((error) => {
        Logger.error(
          `JWeb plugin: unable to obtain DeviceHttpProxy plugin (error: ${error})`
        )
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    deviceHttpProxy,
    sendDHPRequest
  }
}

export default useDeviceHttpProxy
