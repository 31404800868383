import React, { useMemo, useState, useCallback, useEffect } from 'react'
import ShellRootContext, {
  ShellRootContextType,
  ShellRootUseContextReturnType
} from './ShellRootContext'

import defaultResources from '../../assets/locale'

const ShellRootProvider = ({
  children,
  localizationResources,
  interfaces,
  ...props
}: ShellRootContextType) => {
  const { localization } = interfaces.v1
  const [commonsGlobalTranslate, setCommonsGlobalTranslate] =
    useState<ShellRootUseContextReturnType['globalTranslate']>()

  const TranslatorProvider = useMemo(
    () => localization.getReactTranslatorProvider(React),
    [localization]
  )

  useEffect(() => {
    localization
      ?.getGlobalTranslatorFunction?.()
      ?.then((t) => setCommonsGlobalTranslate(() => t))
  }, [localization])

  const globalTranslate = useCallback<
    ShellRootUseContextReturnType['globalTranslate']
  >(
    (...args: Parameters<ShellRootUseContextReturnType['globalTranslate']>) => {
      return commonsGlobalTranslate?.(...args) || ''
    },
    [commonsGlobalTranslate]
  )

  return (
    <ShellRootContext.Provider
      value={{ ...props, globalTranslate, interfaces }}
    >
      <TranslatorProvider resources={localizationResources || defaultResources}>
        {children}
      </TranslatorProvider>
    </ShellRootContext.Provider>
  )
}

export default ShellRootProvider
