import React, { useContext, useMemo, useState } from 'react'
import { FeatureFlagContext } from 'src/store/FeatureFlagContext'
import { UserContext } from 'src/store/UserContext'
import {
  ACTIVATION_PHASE,
  BIZ_MODEL,
  EMPTY_FUNCTION,
  FEATURE_FLAG_KEYS
} from 'src/config/constants'

export const ActivationContext = React.createContext({
  phase: 0,
  setPhase: EMPTY_FUNCTION,
  totalPhases: ACTIVATION_PHASE.DEVICE_REGISTRATION,
  rtpEligible: false
})

const ActivationProvider = (props) => {
  const { flags } = useContext(FeatureFlagContext)
  const { selectedBizModel } = useContext(UserContext)
  const [phase, setPhase] = useState(ACTIVATION_PHASE.ENABLE_WEB_SERVICES)
  const rtpEligible =
    selectedBizModel === BIZ_MODEL.E2E &&
    flags?.[FEATURE_FLAG_KEYS.enableRtpValidation]
  const totalPhases = rtpEligible
    ? ACTIVATION_PHASE.RTP_VALIDATION
    : ACTIVATION_PHASE.DEVICE_REGISTRATION

  const state = useMemo(
    () => ({
      phase,
      setPhase,
      totalPhases,
      rtpEligible
    }),
    [phase, rtpEligible, totalPhases]
  )

  return (
    <ActivationContext.Provider value={state}>
      {props.children}
    </ActivationContext.Provider>
  )
}

export default ActivationProvider
