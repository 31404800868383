import React, { lazy, Suspense, useContext } from 'react'
import { Route, Routes } from 'react-router-dom'
import { ErrorContext } from 'src/store/ErrorContext'
import PortalEntry from '../../pages/PortalEntry'
import { Spinner } from 'src/components/Spinner'

const Activation = lazy(() =>
  import(/* webpackChunkName: "activation" */ '../../pages/Activation')
)
const ActivationFailed = lazy(() =>
  import(
    /* webpackChunkName: "activation-failed" */ '../../pages/ActivationFailed'
  )
)
const ActivationSuccess = lazy(() =>
  import(
    /* webpackChunkName: "activation-success" */ '../../pages/ActivationSuccess'
  )
)
const WebServicesCdm = lazy(() =>
  import(
    /* webpackChunkName: "web-services-cdm" */ '../../pages/WebServicesCdm'
  )
)
const WebServicesLedm = lazy(() =>
  import(
    /* webpackChunkName: "web-services-ledm" */ '../../pages/WebServicesLedm'
  )
)

const DeviceOnboarding = () => {
  const { error } = useContext(ErrorContext)
  return (
    <>
      {error && (
        <Suspense fallback={<></>}>
          <ActivationFailed error={error} />
        </Suspense>
      )}
      <Spinner />
      <Routes>
        <Route
          path="activation"
          element={
            <Suspense fallback={<></>}>
              <Activation />
            </Suspense>
          }
        />
        <Route
          path="activation-success"
          element={
            <Suspense fallback={<></>}>
              <ActivationSuccess />
            </Suspense>
          }
        />
        <Route
          path="web-services/cdm"
          element={
            <Suspense fallback={<></>}>
              <WebServicesCdm />
            </Suspense>
          }
        />
        <Route
          path="web-services/ledm"
          element={
            <Suspense fallback={<></>}>
              <WebServicesLedm />
            </Suspense>
          }
        />
        {/* Portal entry path - /device-onboarding/ */}
        <Route
          exact
          path="/"
          element={<PortalEntry />}
        />
      </Routes>
    </>
  )
}
export default DeviceOnboarding
