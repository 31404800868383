import React, { createContext, useEffect, useMemo, useState } from 'react'
import { FEATURE_FLAG_CLIENT, FEATURE_FLAG_KEYS } from 'src/config/constants'

const DEFAULT_FLAGS = {
  [FEATURE_FLAG_KEYS.enableIcc]: false,
  [FEATURE_FLAG_KEYS.enableRtpValidation]: false
}

export const FeatureFlagContext = createContext({
  flags: DEFAULT_FLAGS
})

const FeatureFlagProvider = ({ children, featureFlags }) => {
  const enabled = !!featureFlags?.getClient
  const [ldClient, setLdClient] = useState(null)
  const [flags, setFlags] = useState(DEFAULT_FLAGS)
  const [loadedFlags, setLoadedFlags] = useState(false)

  useEffect(() => {
    if (enabled && !ldClient) {
      featureFlags?.getClient(FEATURE_FLAG_CLIENT).then((client) => {
        setLdClient(client)
      })
    }
  }, [enabled, featureFlags, ldClient])

  useEffect(() => {
    const loadFlags = async () => {
      for (const key of Object.keys(DEFAULT_FLAGS)) {
        const flag = await ldClient.getFeatureFlag({
          key: key,
          defaultValue: DEFAULT_FLAGS[key]
        })
        setFlags((prevFlags) => ({ ...prevFlags, [key]: flag }))
      }
    }
    if (ldClient && !loadedFlags) {
      setLoadedFlags(true)
      loadFlags()
    }
  }, [ldClient, loadedFlags])

  const state = useMemo(
    () => ({
      flags
    }),
    [flags]
  )
  return (
    <FeatureFlagContext.Provider value={state}>
      {children}
    </FeatureFlagContext.Provider>
  )
}

export default FeatureFlagProvider
