import React, { useContext, useMemo } from 'react'
import { ConfigContext } from 'src/store/ConfigContext'

export const AppContext = React.createContext({
  osType: null,
  appVersion: null,
  countryRegionIso: null
})

const AppProvider = (props) => {
  const { sessionContext } = useContext(ConfigContext)
  const osType = sessionContext?.app?.osType || null
  const appVersion = sessionContext?.app?.appVersion || null
  const countryRegionIso = sessionContext?.app?.countryRegionIso || null

  const state = useMemo(
    () => ({
      osType,
      appVersion,
      countryRegionIso
    }),
    [osType, appVersion, countryRegionIso]
  )

  return (
    <AppContext.Provider value={state}>{props.children}</AppContext.Provider>
  )
}

export default AppProvider
