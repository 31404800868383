import { Stack } from '@jarvis/web-stratus-client'
import { ContentStackStacks } from '../config/constants'
import { Stack as ContentStack } from 'contentstack'

let STACK = null

const getCredentials = (env) => {
  switch (env) {
    case Stack.stage:
      return ContentStackStacks.stage
    case Stack.prod:
      return ContentStackStacks.prod
    default:
      return ContentStackStacks.pie
  }
}

export const getStack = (env) => {
  const { delivery_token, env_name } = getCredentials(env)
  if (STACK) {
    return STACK
  }
  STACK = new ContentStack({
    api_key: ContentStackStacks.api_key,
    delivery_token,
    environment: env_name
  })
  return STACK
}

export const reset = () => {
  STACK = null
}
