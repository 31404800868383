import { useContext, useEffect, useState, useCallback } from 'react'
import { Stack } from '@jarvis/web-stratus-client'
import { getStack } from '../clients/ContentStackClient'
import { ConfigContext } from '../store/ConfigContext'
import { ErrorContext } from '../store/ErrorContext'
import {
  generateError,
  sanitize,
  findLocaleFallback,
  Logger
} from '../utils/helpers'
import {
  CONTENT_STACK_EMPTY_ERROR,
  CONTENT_STACK_CATCH_ALL_ERROR,
  CONTENT_STACK_LOCALE_ERROR
} from '../config/constants'

const LANGUAGE_NOT_FOUND = 'Language was not found. Please try again.'
const EMPTY_QUERY = "The requested entry doesn't exist."

const useContentStack = (
  content_type,
  parsing_function = (data) => data,
  additional_params = {},
  hold_init = false
) => {
  const [holdInit, setHoldInit] = useState(hold_init)
  const [pageData, setPageData] = useState(null)
  const [dataRetrieved, setDataRetrieved] = useState(false)
  const [currentlyFetching, setCurrentlyFetching] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const { stack, shellLanguage, shellCountry } = useContext(ConfigContext)
  const { setError } = useContext(ErrorContext)

  const buildQuery = useCallback(
    (entry_uid, content_type_uid, additional_params) => {
      const { printer_type } = additional_params
      let query = Stack.current.ContentType(content_type_uid)
      if (entry_uid) {
        return query.Entry(entry_uid)
      }
      query = query.Query()
      if (printer_type) query = query.where('printer_type', printer_type)
      return query
    },
    []
  )

  const doQuery = useCallback(
    (content_type, locale, additional_params) => {
      const { entry_uid, content_type_uid } = content_type
      const query = buildQuery(entry_uid, content_type_uid, additional_params)
      const request = query.language(locale).includeFallback().toJSON()
      if (entry_uid) {
        return request.fetch() // Fetching entries uses a different interface
      }
      return request.findOne()
    },
    [buildQuery]
  )

  const startQuery = useCallback(
    ({ content_type, parsing_function, additional_params }) => {
      const locale = findLocaleFallback(shellLanguage, shellCountry)
        .toLowerCase()
        .replace('_', '-')
      setCurrentlyFetching(true)
      doQuery(content_type, locale, additional_params)
        .then((d) => {
          setDataRetrieved(true)
          Logger.log(`Fetched Content Stack - success - ${JSON.stringify(d)}`)
          setPageData(sanitize(parsing_function(d)))
        })
        .catch((e) => {
          let code
          setDataRetrieved(true)
          switch (e?.error_message) {
            case EMPTY_QUERY:
              code = CONTENT_STACK_EMPTY_ERROR
              break
            case LANGUAGE_NOT_FOUND:
              code = CONTENT_STACK_LOCALE_ERROR
              break
            default:
              code = CONTENT_STACK_CATCH_ALL_ERROR
          }
          const { entry_uid, content_type_uid } = content_type
          const { printer_type } = additional_params

          const error = [
            'Fetched Content Stack - failure',
            code,
            entry_uid || content_type_uid,
            printer_type || '',
            locale
          ].join(' - ')

          Logger.warn(error)
          setError(generateError({ errorType: code, reason: e }))
        })
        .finally(() => {
          setCurrentlyFetching(false)
        })
    },
    [shellLanguage, shellCountry, doQuery, setError]
  )

  useEffect(() => {
    if (!initialized) {
      Stack.current = getStack(stack)
      setInitialized(true)
    }
  }, [initialized, stack])

  useEffect(() => {
    const shouldStartQuery =
      initialized &&
      !holdInit &&
      !currentlyFetching &&
      !dataRetrieved &&
      content_type
    if (!shouldStartQuery) return
    const queryRef = startQuery.bind(null, {
      content_type,
      parsing_function,
      additional_params
    })
    queryRef()
  }, [
    content_type,
    currentlyFetching,
    dataRetrieved,
    parsing_function,
    initialized,
    holdInit,
    additional_params,
    startQuery
  ])

  const init = () => {
    setHoldInit(false)
  }

  return { pageData, init }
}

export default useContentStack
