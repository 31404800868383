import useCdm from './useCdm'
import useLedm from './useLedm'
import { Logger } from 'src/utils/helpers'

const usePrinter = ({
  isLedm = false,
  discoveryTree = null,
  iccManifest = null
} = {}) => {
  const _discoveryTree = JSON.parse(discoveryTree)
  const _iccManifest = JSON.parse(iccManifest)
  const {
    fetchServicesDiscovery,
    fetchSystemIdentity,
    fetchInternetDiagnostics,
    patchInternetDiagnostics
  } = useCdm()
  const {
    fetchDiscoveryTree,
    fetchProductConfigDyn,
    fetchEPrintManifest: _fetchEPrintManifest,
    fetchIccManifest: _fetchIccManifest,
    fetchGen2CloudServiceManifest: _fetchGen2CloudServiceManifest,
    fetchIccStatus: _fetchIccStatus,
    patchIccJob
  } = useLedm()

  const fetchDiscovery = async () => {
    const resp = isLedm
      ? await fetchDiscoveryTree()
      : await fetchServicesDiscovery()
    if (!resp?.body?.data) return null
    return resp.body.data
  }

  const fetchDeviceInfo = async () => {
    const result = {
      productNumber: null,
      uuid: null,
      serialNumber: null,
      modelName: null
    }
    const resp = isLedm
      ? await fetchProductConfigDyn(_discoveryTree)
      : await fetchSystemIdentity(_discoveryTree)
    if (!resp?.body?.data) return result
    if (isLedm) {
      result.productNumber =
        resp.body.data?.['prdcfgdyn2:ProductConfigDyn']?.[
          'prdcfgdyn:ProductInformation'
        ]?.['dd:ProductNumber']
      result.uuid =
        resp.body.data?.['prdcfgdyn2:ProductConfigDyn']?.[
          'prdcfgdyn:ProductInformation'
        ]?.['dd:UUID']
      result.serialNumber =
        resp.body.data?.['prdcfgdyn2:ProductConfigDyn']?.[
          'prdcfgdyn:ProductInformation'
        ]?.['dd:SerialNumber']
      result.modelName =
        resp.body.data?.['prdcfgdyn2:ProductConfigDyn'][
          'prdcfgdyn:ProductInformation'
        ]['dd:MakeAndModelBase']
    } else {
      result.productNumber = resp.body.data?.['productNumber']
      result.uuid = resp.body.data?.['deviceUuid']
      result.serialNumber = resp.body.data?.['serialNumber']
      result.modelName = resp.body.data?.['makeAndModel']['base']
    }
    return result
  }

  const fetchEPrintManifest = async () => {
    if (!isLedm) {
      Logger.warn('CDM does not support ePrint manifest')
      return null
    }
    const resp = await _fetchEPrintManifest(_discoveryTree)
    if (!resp?.body?.data) return null
    return resp.body.data
  }

  const fetchIccManifest = async () => {
    if (!isLedm) {
      Logger.warn('CDM does not support ICC manifest')
      return null
    }
    const resp = await _fetchIccManifest(_discoveryTree)
    if (!resp?.body?.data) return null
    return resp.body.data
  }

  const fetchGen2CloudServiceManifest = async () => {
    if (!isLedm) {
      Logger.warn('CDM does not support Gen2 Cloud Service manifest')
      return null
    }
    const resp = await _fetchGen2CloudServiceManifest(_discoveryTree)
    if (!resp?.body?.data) return null
    return resp.body.data
  }

  const fetchIccStatus = async (isIccOverCdm = false) => {
    if (!isIccOverCdm) {
      return await _fetchIccStatus(_iccManifest)
    }
    return await fetchInternetDiagnostics(_discoveryTree)
  }

  const patchIcc = async (version, isIccOverCdm = false) => {
    if (!isIccOverCdm) {
      return await patchIccJob(_iccManifest)
    }
    return await patchInternetDiagnostics(_discoveryTree, version)
  }

  return {
    fetchDiscovery,
    fetchDeviceInfo,
    fetchEPrintManifest,
    fetchIccManifest,
    fetchGen2CloudServiceManifest,
    fetchIccStatus,
    patchIcc
  }
}

export default usePrinter
