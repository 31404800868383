import React from 'react'
import { Container } from './styles'
import { BrowserRouter as Router } from 'react-router-dom'
import '@veneer/styles/typography/typography.css'
import { Stack } from '@jarvis/web-stratus-client'
import { useShellRootContext } from 'src/contexts/ShellRoot'
import ErrorProvider from 'src/store/ErrorContext'
import ConfigProvider from 'src/store/ConfigContext'
import PrinterProvider from 'src/store/PrinterContext'
import UserProvider from 'src/store/UserContext'
import App from './App'
import FeatureFlagProvider from 'src/store/FeatureFlagContext'
import AppProvider from 'src/store/AppContext'
import ActivationProvider from 'src/store/ActivationContext'

const AppWrapper = ({ stack = Stack.pie }) => {
  // Use the ShellRootContext to access the shell features.
  const { t, interfaces = {} } = useShellRootContext?.() || {}
  const {
    v1: {
      analytics,
      navigation,
      store,
      localization,
      serviceRouting,
      monitoring,
      featureFlags,
      tenantHandlerInterface
    },
    v2: { authProvider }
  } = interfaces
  return (
    <Container>
      <Router>
        <ErrorProvider>
          <FeatureFlagProvider featureFlags={featureFlags}>
            <ConfigProvider
              authProvider={authProvider}
              navigation={navigation}
              analytics={analytics}
              store={store}
              stack={stack}
              localization={localization}
              serviceRouting={serviceRouting}
              monitoring={monitoring}
              t={t}
              tenantHandler={tenantHandlerInterface}
            >
              <AppProvider>
                <UserProvider>
                  <PrinterProvider>
                    <ActivationProvider>
                      <App />
                    </ActivationProvider>
                  </PrinterProvider>
                </UserProvider>
              </AppProvider>
            </ConfigProvider>
          </FeatureFlagProvider>
        </ErrorProvider>
      </Router>
    </Container>
  )
}

export default AppWrapper
