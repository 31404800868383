import { createContext } from 'react'
import { PromiseReturnType } from 'src/types/typeHandlers'
import { MfePropsType } from 'src/types/mfeProps'
import {
  InterfacesType,
  TranslatorFunctionResourcePropType,
  TranslatorFunctionType
} from 'src/types/shell'
import useToast from '@veneer/core/dist/esm/scripts/toast_container/use_toast'

export type ShellRootContextType = {
  children?: React.ReactNode
  interfaces: InterfacesType
  t: TranslatorFunctionType
  useToast: typeof useToast
  localizationResources?: TranslatorFunctionResourcePropType
} & MfePropsType

export type GlobalTranslateType = PromiseReturnType<
  InterfacesType['v1']['localization']['createTranslatorFunction']
>

export type ShellRootUseContextReturnType = ShellRootContextType & {
  globalTranslate: GlobalTranslateType
}

const ShellRootContext = createContext({} as ShellRootUseContextReturnType)

export default ShellRootContext
