import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  ANALYTICS,
  DEVICE_NOT_SUPPORTED,
  RESULT,
  DHP_AVATAR_REGISTRATION_REQUIRED_ERROR,
  DHP_EPRINT_MANIFEST_REQUIRED_ERROR
} from 'src/config/constants'
import { ConfigContext } from 'src/store/ConfigContext'
import { PrinterContext } from 'src/store/PrinterContext'
import useSimpleUiAnalytics from 'src/hooks/useSimpleUiAnalytics'
import { generateError } from 'src/utils/helpers'

const { SCREENS, MODES } = ANALYTICS

const PortalEntry = () => {
  const navigate = useNavigate()
  const [printerInitiated, setPrinterInitiated] = useState(false)
  const [nextPageLoaded, setNextPageLoaded] = useState(false)
  const { closeServiceInstance, sessionId } = useContext(ConfigContext)
  const { isLedm, init, firmwareProtocol, supportConfig, ePrintManifest } =
    useContext(PrinterContext)
  const { fireScreenDisplayed } = useSimpleUiAnalytics(SCREENS.START)
  fireScreenDisplayed({
    mode: MODES.INTERFACE[isLedm]
  })

  // Firmware protocol eligibility check - runs on initial render
  useEffect(() => {
    if (firmwareProtocol === DEVICE_NOT_SUPPORTED) {
      closeServiceInstance(RESULT.INELIGIBLE)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      sessionId &&
      !printerInitiated &&
      firmwareProtocol !== DEVICE_NOT_SUPPORTED
    ) {
      setPrinterInitiated(true)
      init()
    }
  }, [sessionId, init, printerInitiated, firmwareProtocol, setPrinterInitiated])

  // CDM registration ineligibility check
  useEffect(() => {
    if (!isLedm && supportConfig.registration === false) {
      closeServiceInstance(
        RESULT.INELIGIBLE,
        null,
        generateError({ errorType: DHP_AVATAR_REGISTRATION_REQUIRED_ERROR })
      )
    }
  }, [closeServiceInstance, isLedm, supportConfig.registration])

  // LEDM registration ineligibility check
  useEffect(() => {
    if (
      isLedm &&
      supportConfig.cdmServices === false &&
      supportConfig.registrationOverLedm === false
    ) {
      closeServiceInstance(
        RESULT.INELIGIBLE,
        null,
        generateError({ errorType: DHP_EPRINT_MANIFEST_REQUIRED_ERROR })
      )
    }
  }, [
    closeServiceInstance,
    isLedm,
    supportConfig.cdmServices,
    supportConfig.registration,
    supportConfig.registrationOverLedm
  ])

  // Registration over CDM eligibility check
  useEffect(() => {
    if (!nextPageLoaded && supportConfig.registration === true) {
      setNextPageLoaded(true)
      navigate('web-services/cdm')
    }
  }, [navigate, nextPageLoaded, supportConfig.registration])

  // Registration over LEDM eligibility check
  useEffect(() => {
    if (
      !nextPageLoaded &&
      supportConfig.registrationOverLedm === true &&
      supportConfig.cdmServices === false &&
      ePrintManifest
    ) {
      setNextPageLoaded(true)
      navigate('web-services/ledm')
    }
  }, [
    ePrintManifest,
    navigate,
    nextPageLoaded,
    supportConfig.cdmServices,
    supportConfig.registrationOverLedm
  ])

  return <></>
}

export default PortalEntry
