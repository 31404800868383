import { RESOURCE_URI_FALLBACK } from 'src/config/constants'

import { Logger } from '../helpers'

const { LEDM: URI } = RESOURCE_URI_FALLBACK

const getResourceUri = (discoveryTree, type) => {
  try {
    return discoveryTree['ledm:DiscoveryTree']['ledm:SupportedTree'].find(
      (service) => service['dd:ResourceType'] === type
    )['dd:ResourceURI']
  } catch (e) {
    let log = `Not able to find resource uri for ${type} type - ${e}`
    if (discoveryTree) log += ` - ${JSON.stringify(discoveryTree)}`
    Logger.warn(log)
    return null
  }
}

const getManifestUri = (discoveryTree, type) => {
  try {
    return discoveryTree['ledm:DiscoveryTree']['ledm:SupportedIfc'].find(
      (service) => service['dd:ResourceType'] === type
    )['ledm:ManifestURI']
  } catch (e) {
    let log = `Not able to find manifest uri for ${type} type - ${e}`
    if (discoveryTree) log += ` - ${JSON.stringify(discoveryTree)}`
    Logger.warn(log)
    return null
  }
}

const _parseResourceMapUri = (resourceMap, key, type) => {
  let resourcePath = ''
  const rootPath = resourceMap['map:ResourceLink']
    ? resourceMap['map:ResourceLink']['dd:ResourceURI']
    : ''
  if (resourceMap['map:ResourceMap']) {
    resourcePath = _parseResourceMapUri(
      resourceMap['map:ResourceMap'],
      key,
      type
    )
  } else {
    const nodes = resourceMap['map:ResourceNode'].length
      ? resourceMap['map:ResourceNode']
      : [resourceMap['map:ResourceNode']]
    for (const node of nodes) {
      if (node['map:ResourceMap']) {
        resourcePath = _parseResourceMapUri(node, key, type)
      } else if (node['map:ResourceType'][key] === type) {
        resourcePath = node['map:ResourceLink']['dd:ResourceURI']
        break
      }
    }
  }
  return resourcePath ? rootPath + resourcePath : null
}

const getResourceUriFromManifest = (manifest, resourceKey, resourceType) => {
  try {
    return _parseResourceMapUri(
      manifest['man:Manifest']['map:ResourceMap'],
      resourceKey,
      resourceType
    )
  } catch (e) {
    let log = `Not able to find ${resourceKey} == ${resourceType} - ${e}`
    if (manifest) log += ` - ${JSON.stringify(manifest)}`
    Logger.warn(log)
    return null
  }
}

export const getProductConfigDynHref = (discoveryTree) => {
  return (
    getResourceUri(discoveryTree, 'ledm:hpLedmProductConfigDyn') ||
    URI.productConfigDyn
  )
}

export const getEPrintManifestHref = (discoveryTree, fallback = true) => {
  return (
    getManifestUri(discoveryTree, 'ledm:hpePrintManifest') ||
    (fallback ? URI.ePrintManifest : null)
  )
}

export const getEPrintConfigDynHref = (manifestTree) => {
  return (
    getResourceUriFromManifest(
      manifestTree,
      'ep:ePrintResourceType',
      'ePrintConfigDyn'
    ) || URI.ePrintConfigDyn
  )
}

export const getIccManifestHref = (discoveryTree, fallback = true) => {
  return (
    getManifestUri(discoveryTree, 'ledm:hpLedmUtilities') ||
    (fallback ? URI.iccManifest : null)
  )
}

export const getIccStatusHref = (manifestTree) => {
  return (
    getResourceUriFromManifest(
      manifestTree,
      'ut:UtilitiesResourceType',
      'ICCStatus'
    ) || URI.iccStatus
  )
}

export const getIccJobHref = (manifestTree) => {
  return (
    getResourceUriFromManifest(
      manifestTree,
      'ut:UtilitiesResourceType',
      'ICCJob'
    ) || URI.iccJob
  )
}

export const getCloudServiceManifestHref = (discoveryTree, fallback = true) => {
  return (
    getManifestUri(discoveryTree, 'ledm:hpLedmGenTwoCloudServiceManifest') ||
    (fallback ? URI.gen2CloudServiceManifest : null)
  )
}

export const getClaimPostcardHref = (manifestTree) => {
  return (
    getResourceUriFromManifest(
      manifestTree,
      'gc:Gen2CloudServiceResourceType',
      'claimPostcard'
    ) || URI.gen2CloudServicePostcard
  )
}

export const getCDMServicesDiscoveryHref = (discoveryTree) => {
  return getManifestUri(discoveryTree, 'cdm:servicesDiscovery') || null
}
