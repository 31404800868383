import { useContext, useState } from 'react'
import { ANALYTICS } from '../config/constants'
import { ConfigContext } from '../store/ConfigContext'
import { PrinterContext } from '../store/PrinterContext'

const buildEventPayload = (action, activity, control, screen) => {
  const eventDetail = {
    action,
    screenMode: screen.mode,
    screenName: screen.name,
    screenPath: screen.path,
    version: '1.4.0'
  }
  if (activity) {
    eventDetail.activity = activity
  }
  if (control.detail) {
    eventDetail.controlDetail = control.detail
  }
  if (control.name) {
    eventDetail.controlName = control.name
  }
  if (control.actionAuxParams) {
    eventDetail.actionAuxParams = control.actionAuxParams
  }
  return {
    dateTime: new Date().toISOString(),
    eventDetailType:
      'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
    eventCategory: 'simpleUi',
    version: '1.4.0',
    eventDetail
  }
}
const { SCREENS, CONTROLS } = ANALYTICS

function useSimpleUiAnalytics(screen, checkDone = true) {
  const { publishCdmEvent } = useContext(ConfigContext)
  const [done, setDone] = useState(false)
  const { productNumber, uuid } = useContext(PrinterContext)
  const { activity, screenPath, screenName } = screen

  const _checkScreenValidity = () => {
    if (Object.values(SCREENS).indexOf(screen) === -1) {
      throw new Error('Invalid screen')
    }
  }

  const _isControlValid = (name) => {
    if (Object.values(CONTROLS).indexOf(name) === -1) {
      throw new Error('Invalid button')
    }
  }

  const _buildEvent = (action, { name, detail, mode, errorCode = null }) => {
    if (mode) {
      const modes = Object.values(ANALYTICS.MODES).reduce((items, items1) => {
        return [...Object.values(items), ...Object.values(items1)]
      }, {})
      if (modes.indexOf(mode) === -1) {
        throw new Error('Invalid mode')
      }
    }
    let actionAuxParams = null
    let params = {}
    if (productNumber) params['productNumber'] = productNumber
    if (uuid) params['uuid'] = uuid
    if (errorCode) {
      params['errorCode'] = errorCode
    }
    if (Object.keys(params).length) {
      actionAuxParams = new URLSearchParams(params).toString()
    }
    return [
      buildEventPayload(
        action,
        activity,
        {
          name,
          detail,
          actionAuxParams
        },
        {
          name: screenName,
          mode,
          path: screenPath
        }
      )
    ]
  }

  const fireScreenDisplayed = (props = {}) => {
    _checkScreenValidity()
    if (checkDone && done && !props.force) return
    setDone(true)
    publishCdmEvent(_buildEvent('ScreenDisplayed', props))
  }

  const fireButtonClick = (name, props = {}) => {
    _isControlValid(name)
    publishCdmEvent(_buildEvent('ControlButtonClicked', { name, ...props }))
  }

  const fireControlHyperLinkClicked = (name, detail, props = {}) => {
    publishCdmEvent(
      _buildEvent('ControlHyperLinkClicked', { name, detail, ...props })
    )
  }

  const fireModuleInitialized = (name, detail, props = {}) => {
    _isControlValid(name)
    publishCdmEvent(
      _buildEvent('ModuleInitialized', { name, detail, ...props })
    )
  }

  return {
    fireButtonClick,
    fireControlHyperLinkClicked,
    fireScreenDisplayed,
    fireModuleInitialized
  }
}

export default useSimpleUiAnalytics
