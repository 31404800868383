import {
  DEFAULT_ERROR_CODE,
  DHP_ERROR_CODE,
  DHP_NON_200_ERROR_CODE,
  SERVICE_ID,
  TENANT_INTERFACE_ERROR
} from '../config/constants'
import { languageFallback, localeFallback } from '../assets/locale'
import DOMPurify from 'dompurify'

const packageJson = require('../../package.json')

const logWithPrefix = (severity, log) => {
  console[severity](
    `DeviceOnboarding - ${packageJson.version} - `,
    typeof log === 'string' || log instanceof String ? log : JSON.stringify(log)
  )
}

export const Logger = {
  debug: (log) => logWithPrefix('debug', log),
  log: (log) => logWithPrefix('log', log),
  info: (log) => logWithPrefix('info', log),
  warn: (log) => logWithPrefix('warn', log),
  error: (log) => logWithPrefix('error', log)
}

export const generateError = (error) => {
  const errorCode = DHP_ERROR_CODE[error.errorType]
    ? DHP_ERROR_CODE[error.errorType]
    : error.statusCode
    ? DHP_NON_200_ERROR_CODE
    : DEFAULT_ERROR_CODE

  return {
    errorCode: `${SERVICE_ID}.${errorCode}`,
    errorDescription: `${error.errorType || error?.statusCode} - ${
      error.reason ? error.reason : 'Error calling DHP plugin (no reason)'
    }`,
    errorCodeLabel: error.statusCode
      ? `${errorCode}_${error.statusCode}`
      : errorCode,
    payload: error.payload,
    resourceUri: error.resourceUri
  }
}

export const isDHPError = (response) => {
  return !!(
    response?.errorType || range(200, 299).indexOf(response?.statusCode) === -1
  )
}

export const range = (start, end) => {
  return Array(end - start + 1)
    .fill(0)
    .map((_, idx) => start + idx)
}

export const generateApiError = (error, resourceUri = null) => {
  const [prefix, status, code] = error.split('_')
  return {
    errorCode: `${SERVICE_ID}.${prefix}.${status}.${code}`,
    errorDescription: `${prefix} - ${status} - ${code}`,
    errorCodeLabel: error,
    statusCode: parseInt(status),
    resourceUri: resourceUri
  }
}

export function getHighestResolutionImage(imagesArray) {
  if (!imagesArray) return null

  let highestResolutionImage = null
  let highestResolution = 0

  for (let image of imagesArray) {
    const resolutionComponent = image.split('/').pop()
    const size = resolutionComponent.split('.')[0].split('x')
    const resolution = parseInt(size[0]) * parseInt(size[1])
    if (resolution > highestResolution) {
      highestResolution = resolution
      highestResolutionImage = image
    }
  }

  return highestResolutionImage
}

export const findLocaleFallback = (language, country) => {
  const locale = `${language}_${country}`
  if (localeFallback[locale]) {
    return localeFallback[locale]
  }
  return languageFallback[language] ? languageFallback[language] : 'en_US'
}

export const generateErrorCode = (error, fallback) => {
  let errorCode
  try {
    errorCode = error.response.data.errors.pop().code
  } catch {
    errorCode = fallback
  }
  return errorCode
}

export const fetchTenantId = async (tenantHandler, accountId) => {
  try {
    let tenantId = tenantHandler.getTenantId('tenant')
    if (!tenantId) {
      Logger.log('Performing token exchange to set tenant ID')
      await tenantHandler.setTenant({
        tenantId: accountId,
        authContext: 'tenant',
        preventReload: true
      })
      tenantId = tenantHandler.getTenantId('tenant')
    }
    return tenantId
  } catch (error) {
    Logger.warn('Unable to perform token exchange and set tenant ID', error)
    throw generateApiError(TENANT_INTERFACE_ERROR)
  }
}

/**
 * Receive a JSON object and sanitize every string found in the object, including all children.
 * This only allows <b>target</b> properties to keep the behavior of anchor links.
 *
 * @param jsonData {{}} JSON that needs to be sanitized
 * @returns {{}} Sanitized JSON object
 */
export const sanitize = (jsonData) => {
  if (!jsonData) {
    return jsonData
  } else if (jsonData.constructor === {}.constructor) {
    Object.keys(jsonData).forEach((nd) => {
      jsonData[nd] = sanitize(jsonData[nd])
    })
    return jsonData
  } else if (Array.isArray(jsonData)) {
    jsonData.forEach((nd, index) => {
      jsonData[index] = sanitize(nd)
    })
    return jsonData
  } else {
    return DOMPurify.sanitize(jsonData, { ADD_ATTR: ['target'] })
  }
}
