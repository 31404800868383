import { Logger, generateError, isDHPError } from '../utils/helpers'
import {
  getAvatarRegistrationHref,
  getClaimPostcardHref,
  getFingerprintHref,
  getSystemIdentityHref,
  getInternetDiagnosticsHref
} from '../utils/cdm/helpers'
import { useContext } from 'react'
import { ConfigContext } from '../store/ConfigContext'
import { ErrorContext } from '../store/ErrorContext'
import {
  CDM_DEFAULT_VERSION,
  REGISTRATION_STATE,
  RESOURCE_URI_FALLBACK,
  SERVICE_ID
} from 'src/config/constants'

const useCdm = () => {
  const { sessionId, sendDHPRequest: plugin } = useContext(ConfigContext)
  const { setError } = useContext(ErrorContext)

  const _request = async (payload, useCache, handleError, parseData = true) => {
    Logger.log(`Fetching DHP CDM - payload ${JSON.stringify(payload)}`)
    const response = await plugin(payload, useCache)
    const payloadData = payload?.body?.data ? payload.body.data : null

    if (handleError && isDHPError(response)) {
      setError(
        generateError({
          ...response,
          payload: payloadData,
          resourceUri: payload.path
        })
      )
      return null
    }

    if (response?.body?.data) {
      const data = response.body.isBase64Encoded
        ? atob(response.body.data)
        : response.body.data
      try {
        response.body.data = parseData ? JSON.parse(data) : data
      } catch (e) {
        if (handleError) {
          setError(
            generateError({
              errorType: 'unableToDecode',
              reason: e,
              payload: payloadData,
              resourceUri: payload.path
            })
          )
          return null
        }
      }
    }

    Logger.log(`Fetched DHP CDM - response ${JSON.stringify(response)}`)
    return response
  }

  const _fetch = async (
    href,
    useCache = false,
    handleError = true,
    parseData = true
  ) => {
    const options = {
      path: href,
      sessionId: sessionId,
      method: 'GET',
      allowUserInteraction: true,
      calledBy: SERVICE_ID
    }
    return _request(options, useCache, handleError, parseData)
  }

  const _write = async (href, payload, method, handleError) => {
    const options = {
      path: href,
      sessionId: sessionId,
      method: method,
      body: { data: payload },
      allowUserInteraction: true,
      calledBy: SERVICE_ID
    }
    return _request(options, false, handleError)
  }

  const put = async (href, payload, handleError = true) => {
    return _write(href, payload, 'PUT', handleError)
  }

  const patch = async (href, payload, handleError = true) => {
    return _write(href, payload, 'PATCH', handleError)
  }

  const fetchServicesDiscovery = async (handleError = true) => {
    return _fetch(RESOURCE_URI_FALLBACK.CDM.discoveryTree, true, handleError)
  }

  const fetchSystemIdentity = async (servicesDiscovery) => {
    const href = getSystemIdentityHref(servicesDiscovery)
    return _fetch(href, true)
  }

  const fetchFingerprint = async (servicesDiscovery) => {
    const href = getFingerprintHref(servicesDiscovery)
    return _fetch(href, true, false, false)
  }

  const fetchClaimPostcard = async (servicesDiscovery) => {
    const href = getClaimPostcardHref(servicesDiscovery)
    return _fetch(href, true, false, false)
  }

  const fetchAvatarRegistration = async (servicesDiscovery) => {
    const href = getAvatarRegistrationHref(servicesDiscovery)
    const response = await _fetch(href, false, false)

    return {
      registrationUri: response?.body?.data?.links
        ? response.body.data.links.find(
            (link) => link['rel'] === REGISTRATION_STATE.NEW_REGISTRATION
          )?.href
        : null,
      cloudServicesEnabled: response?.body?.data?.cloudServicesEnabled,
      registrationState: response?.body?.data?.registration?.registrationState,
      statusCode: response?.statusCode,
      version: response?.body?.data?.version || CDM_DEFAULT_VERSION,
      errorType: response?.errorType
    }
  }

  const putAvatarRegistration = async (newRegistrationUri, version) => {
    const payload = JSON.stringify({
      version: version,
      registrationTriggeredBy: 'sw'
    })
    return await put(newRegistrationUri, payload)
  }

  const fetchInternetDiagnostics = async (servicesDiscovery) => {
    const href = getInternetDiagnosticsHref(servicesDiscovery)
    const response = await _fetch(href, false, false)
    return {
      errorDetails: response?.body?.data?.failureReason,
      state: response?.body?.data?.state,
      version: response?.body?.data?.version || CDM_DEFAULT_VERSION,
      statusCode: response?.statusCode,
      errorType: response?.errorType
    }
  }

  const patchInternetDiagnostics = async (servicesDiscovery, version) => {
    const href = getInternetDiagnosticsHref(servicesDiscovery)
    const payload = JSON.stringify({
      version: version,
      state: REGISTRATION_STATE.PROCESSING
    })
    return patch(href, payload, false)
  }

  return {
    get: _fetch,
    put,
    patch,
    fetchServicesDiscovery,
    fetchSystemIdentity,
    fetchFingerprint,
    fetchClaimPostcard,
    fetchAvatarRegistration,
    putAvatarRegistration,
    fetchInternetDiagnostics,
    patchInternetDiagnostics
  }
}

export default useCdm
