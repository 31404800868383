import { Logger, generateError, isDHPError } from '../utils/helpers'
import {
  getCloudServiceManifestHref,
  getEPrintManifestHref,
  getIccManifestHref,
  getProductConfigDynHref,
  getClaimPostcardHref,
  getEPrintConfigDynHref,
  getIccStatusHref,
  getIccJobHref
} from '../utils/ledm/helpers'
import { useContext } from 'react'
import { ConfigContext } from '../store/ConfigContext'
import { ErrorContext } from '../store/ErrorContext'
import {
  RESOURCE_URI_FALLBACK,
  SERVICE_ID,
  EPRINT_REGISTRATION_PAYLOAD
} from 'src/config/constants'
const { XMLParser } = require('fast-xml-parser')

const useLedm = () => {
  const { setError } = useContext(ErrorContext)
  const { sessionId, sendDHPRequest: plugin } = useContext(ConfigContext)
  const parser = new XMLParser({
    allowBooleanAttributes: true,
    ignoreDeclaration: true,
    processEntities: false
  })

  const _request = async (payload, useCache, handleError, parseData = true) => {
    Logger.log(`Fetching DHP LEDM - request payload ${JSON.stringify(payload)}`)
    const response = await plugin(payload, useCache)
    const payloadData = payload?.body?.data ? payload.body.data : null

    if (handleError && isDHPError(response)) {
      setError(
        generateError({
          ...response,
          payload: payloadData,
          resourceUri: payload.path
        })
      )
      return null
    }

    if (response?.body?.data) {
      const data = response.body.isBase64Encoded
        ? atob(response.body.data)
        : response.body.data
      try {
        response.body.data = parseData ? parser.parse(data, true) : data
      } catch (e) {
        if (handleError) {
          setError(
            generateError({
              errorType: 'unableToDecode',
              reason: e,
              payload: payloadData,
              resourceUri: payload.path
            })
          )
          return null
        }
      }
    }

    Logger.log(`Fetched DHP LEDM - response ${JSON.stringify(response)}`)
    return response
  }

  const _fetch = async (
    href,
    useCache = false,
    handleError = true,
    parseData = true
  ) => {
    const options = {
      path: href,
      sessionId: sessionId,
      method: 'GET',
      allowUserInteraction: true,
      calledBy: SERVICE_ID
    }
    return _request(options, useCache, handleError, parseData)
  }

  const _write = (href, payload, method, handleError = true) => {
    const options = {
      path: href,
      sessionId: sessionId,
      method: method,
      body: { data: payload },
      headers: {
        'Content-Type': 'text/xml'
      },
      allowUserInteraction: true,
      calledBy: SERVICE_ID
    }
    return _request(options, false, handleError)
  }

  const put = async (href, payload, handleError = true) => {
    return _write(href, payload, 'PUT', handleError)
  }
  const post = async (href, payload) => {
    return _write(href, payload, 'POST')
  }

  const fetchDiscoveryTree = async () => {
    return _fetch(RESOURCE_URI_FALLBACK.LEDM.discoveryTree, true)
  }

  const fetchProductConfigDyn = async (servicesDiscovery) => {
    return _fetch(getProductConfigDynHref(servicesDiscovery), true)
  }

  const fetchEPrintManifest = async (servicesDiscovery) => {
    return _fetch(getEPrintManifestHref(servicesDiscovery), true)
  }

  const fetchIccManifest = async (servicesDiscovery) => {
    return _fetch(getIccManifestHref(servicesDiscovery), true)
  }

  const fetchGen2CloudServiceManifest = async (servicesDiscovery) => {
    const href = getCloudServiceManifestHref(servicesDiscovery)
    return _fetch(href, true)
  }

  const fetchClaimPostcard = async (cloudServiceManifest) => {
    const href = getClaimPostcardHref(cloudServiceManifest)
    return _fetch(href, true, false, false)
  }

  const fetchEPrintRegistrationState = async (ePrintManifest) => {
    const href = getEPrintConfigDynHref(ePrintManifest)
    const resp = await _fetch(href, false, false)

    return {
      state: resp?.body?.data?.['ep:ePrintConfigDyn']?.['ep:RegistrationState'],
      statusCode: resp?.statusCode,
      errorType: resp?.errorType,
      optIn:
        resp?.body?.data?.['ep:ePrintConfigDyn']?.['ep:AllowedServices']?.[
          'ep:OptIn'
        ]
    }
  }

  const patchEPrintRegistrationState = async (ePrintManifest) => {
    const href = getEPrintConfigDynHref(ePrintManifest)
    return await put(href, EPRINT_REGISTRATION_PAYLOAD)
  }

  const fetchIccStatus = async (iccManifest) => {
    const href = getIccStatusHref(iccManifest)
    const response = await _fetch(href, false, false)
    return {
      errorDetails:
        response?.body?.data?.['ut:InternetConnectionStatus']?.[
          'ut:ErrorDetails'
        ]?.['ut:Type'],
      state:
        response?.body?.data?.['ut:InternetConnectionStatus']?.['ut:State'],
      statusCode: response?.statusCode,
      errorType: response?.errorType,
      version: undefined
    }
  }

  const patchIccJob = async (iccManifest) => {
    const href = getIccJobHref(iccManifest)
    return await put(href, null, false)
  }

  return {
    get: _fetch,
    fetchDiscoveryTree,
    fetchProductConfigDyn,
    fetchEPrintManifest,
    fetchIccManifest,
    fetchGen2CloudServiceManifest,
    post,
    put,
    fetchClaimPostcard,
    fetchEPrintRegistrationState,
    patchEPrintRegistrationState,
    fetchIccStatus,
    patchIccJob
  }
}

export default useLedm
